<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation','goods_logo']" ref="list" :rowKey="'id'" :where="where">
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加产品</a-button>
            </template>
            <!-- <template slot="right_btn">
                <a :href="'/manage/crm/outbound_order_export' | export_form" target="_blank">
                    <a-button class="a_btn">导出</a-button>
                </a>
            </template> -->
            <template slot="goods_logo" slot-scope="data">
                <img v-if="data.text" style="width:50px; height:50px" :src="data.text" alt />
                <img v-else style="width:50px; height:50px" src="@/assets/img/null_img.png" alt />
            </template>
            <template slot="operation" slot-scope="data">
                <!-- <a @click="change_goods(data.record)">禁用</a>
                <a-divider type="vertical" /> -->
                <a @click="del_goods(data.record)">删除</a>
                <!-- <a-divider type="vertical" />
                <a @click="go_product(data.record)">承运商</a> -->
            </template>
        </TableList>
        <EditPop @handleSubmit="product_submit" :form_data="form_data" :visible.sync="visible">
		</EditPop>
    </div>
</template>
<script>
    // @ is an alias to /src
    import Form from "@/components/Form";
    import TableList from "@/components/TableList";
    import EditPop from "@/components/EditPop";
    import { get_ware_product,save_ware_product,del_ware_product,getGoodsSelect} from "@/api/push";
    const columns = [{
            title: "商品图片",
            dataIndex: "goods_logo",
            scopedSlots: {
                customRender: "goods_logo"
            }
        },
        {
            title: "商品名称",
            dataIndex: "goods_title",
        },
        {
            title: "drp_id",
            dataIndex: "drp_id",
        },
        // {
        //     title: "规格",
        //     dataIndex: "goods_spec",
        // },
        {
            title: "操作",
            scopedSlots: {
                customRender: "operation"
            }
        }
    ];

    export default {
        name: "Product",
        components: {
            Form,
            TableList,
            EditPop
        },
        data() {
            return {
                get_table_list: get_ware_product,
                columns,
                visible: false,
                where:{warehouse_id:this.$route.query.id},
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [
                        {
                            type: "text",
                            name: "keyword",
                            title: "关键词",
                            placeholder: "请输入商品名称",
                            options: {}
                        }
                    ],
                },
                form_data: {
                    title: "添加产品",
                    // show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "select",
                            name: "goods_id",
                            title: "产品",
                            mode:"multiple",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择产品"
                                }]
                            },
                            list: []
                        }]
                }
            };
        },
        created() {
            
        },
        methods: {
            //修改状态
            change_goods(e) {
                
            },
            //添加
            add() {
                this.get_goods()
                this.visible = true;
            },
            product_submit(e) {
                this.visible = false;
                save_ware_product({
                    data:{
                        good_ids:e.values.goods_id.join(','),
                        ware_id:this.$route.query.id
                    }
                }).then(res => {
                    this.$refs.list.get_list();
                })
            },
            //导出
            download() {
                outbound_order_export({
					data: {
						...this.$refs.list.seo_data
					}
				}).then(res => {
					let a = document.createElement('a');
					a.href = res.url;
                    a.target = '_blank'
					a.click();
				})
            },
            get_goods() {
                getGoodsSelect().then(res=>{
                    var goods = [];
                    res.data.list.forEach(item=>{
                        var g = {key:item.id,value:item.goods_title}
                        goods.push(g)
                    })
                    this.form_data.list = this.$method.set_form_list(this.form_data.list, 'goods_id', 'list', goods)
                })
            },
            del_goods(e) {
                del_ware_product({
                    data:{
                        id:e.id,
                        ware_id:this.$route.query.id
                    }
                }).then(res=>{
                    this.$refs.list.get_list()
                })
            }

        }
    };
</script>
